import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Slideshow from '../components/Slideshow/Slideshow';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';

export const Head = () => {
  return (
    <Seo
      title="ABOUT"
      description="モノレール香春口三萩野駅から程近い
      赤煉瓦の大人の隠れ家レストラン【肉のながおか】では
      九州各地より厳選した黒毛和牛と高級ワインで
      皆様に上質な料理・優雅な空間を提供しております。"
    />
  );
};

const AccessPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="About us"
          subtitle="肉のながおかについて"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <FeatureSectionCenterNoimage
          description={`モノレール香春口三萩野駅から程近い
          赤煉瓦の大人の隠れ家レストラン【肉のながおか】では
          九州各地より厳選した黒毛和牛と高級ワインで
          皆様に上質な料理・優雅な空間を提供しております。
          `}
        />
        <FeatureSectionRight
          imageSrc="/nagaoka2024/t103.jpg"
          imageAlt="Image 1"
          title="肉のながおかについて"
          description={`モノレール香春口三萩野駅から程近い
            赤煉瓦の大人の隠れ家レストラン【肉のながおか】では
            九州各地より厳選した黒毛和牛と高級ワインで
            皆様に上質な料理・優雅な空間を提供しております。
            `}
        />
      </Layout>
    </div>
  );
};

export default AccessPage;
