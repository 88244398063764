// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--9badd";
export var description = "FeatureSection-module--description--0479d";
export var featureSection = "FeatureSection-module--featureSection--dd173";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--62ac6";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--04258";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--38118";
export var head = "FeatureSection-module--head--35275";
export var headSubtext = "FeatureSection-module--headSubtext--240b6";
export var headText = "FeatureSection-module--headText--6d411";
export var title = "FeatureSection-module--title--de90b";